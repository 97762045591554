import { trackEvent, useAuthGuardPageTracker } from '@/analytics'
import { useNavigate } from 'react-router-dom'
import { useMatchSelectPreferences } from '@/pages/asset/selectMatches'
import UploadFileStep from '@/pages/upload/step-components/upload-file'
import BulkUploadLayout from '../BulkLayout'
import { handleFlatfileUploadComplete, isLimitExceededError, useRedirectIfInactive } from '../utils'

const trackingPayload = { page: 'Dashboard', lookup: 'address' } as const

const BulkUploadAddressPage = () => {
  useAuthGuardPageTracker('Starts FlatFile flow', trackingPayload)
  const navigate = useNavigate()
  const matchSelectPreference = useMatchSelectPreferences()

  useRedirectIfInactive()

  const handleComplete = async (newFilename: string, records?: any, batchId?: string) => {
    try {
      await handleFlatfileUploadComplete({
        newFilename,
        records,
        batchId,
        requestPayload: {
          requestEmails: matchSelectPreference.email.active,
          requestPhones: matchSelectPreference.phone.active,
          requestedType: 'address'
        },
        navigate
      })
      trackEvent('Completes FlatFile flow', { page: 'Enrich - Bulk', type: 'address' })
    } catch (error) {
      if (isLimitExceededError(error)) {
        navigate(`/let-us-handle/contact`, { state: { batchId } })
      }
    }
  }

  return (
    <BulkUploadLayout handleComplete={handleComplete}>
      <UploadFileStep />
    </BulkUploadLayout>
  )
}

export default BulkUploadAddressPage
