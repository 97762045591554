import { SpaceConfig } from '@flatfile/api/api'
import { Space, useEvent, useFlatfile, Workbook } from '@flatfile/react'
import { useTimeout } from 'usehooks-ts'
import api from '@flatfile/api'

import { FlatfileRecord } from '@flatfile/plugin-record-hook'
import { workbook } from './workbook'
import { useState } from 'react'
import { handleFlatfileUploadComplete, isLimitExceededError } from '@/pages/lookup/utils'
import { useNavigate } from 'react-router-dom'
import { useMatchSelectPreferences } from '@/pages/asset/selectMatches'
import { trackEvent } from '@/analytics'
import { useTheme } from '@mui/material'

type PropKeys = 'firstName' | 'lastName' | 'fullName' | 'address' | 'city' | 'state' | 'zipCode'

type Record = {
  id: string
  values: {
    [key in PropKeys]: {
      value: string
    }
  }
}

type Address = {
  firstName: string
  lastName: string
  fullName: string
  address: string
  city: string
  state: string
  zipCode: string
}

export type Params = {
  data?: {
    records: Record[]
  }
  sheet?: any
}

const useSpaceConfig = (): SpaceConfig => {
  const theme = useTheme()

  return {
    namespace: 'address-lookup',
    environmentId: process.env.REACT_APP_FLATFILE_ENV as string,
    metadata: {
      theme: {
        root: {
          primaryColor: theme.palette.primary.main
        }
      },
      sidebarConfig: {
        showSidebar: false
      }
    }
  }
}

const FlatfilePortal = () => {
  const [fileName, setFileName] = useState('')

  const navigate = useNavigate()
  const spaceConfig = useSpaceConfig()
  const matchSelectPreference = useMatchSelectPreferences()

  const { open, openPortal } = useFlatfile()

  const delay = !open ? 1000 : null
  useTimeout(openPortal, delay)

  const handleComplete = async (newFilename: string, records?: any, batchId?: string) => {
    try {
      await handleFlatfileUploadComplete({
        newFilename,
        records,
        batchId,
        requestPayload: {
          requestEmails: matchSelectPreference.email.active,
          requestPhones: matchSelectPreference.phone.active,
          requestedType: 'address'
        },
        navigate
      })
      trackEvent('Completes FlatFile flow', { page: 'Enrich - Bulk', type: 'address' })
    } catch (error) {
      if (isLimitExceededError(error)) {
        navigate(`/let-us-handle/contact`, { state: { batchId } })
      }
    }
  }

  useEvent('file:created', async (event) => {
    const { fileId } = event.context

    const file = await api.files.get(fileId)

    setFileName(file.data.name)
  })

  const handleRecord = (record: FlatfileRecord) => {
    const fullName = record.get('fullName') as string
    record.set('fullName', fullName.replace(/,/g, ''))

    return record
  }

  const handleSubmit = async (params: Params) => {
    const { sheet } = params
    const data = await sheet.allData()

    const records = data.records.reduce((acc: Address[], record: Record) => {
      const { firstName, lastName, fullName, address, city, state, zipCode } = record.values

      const item = {
        firstName: firstName.value ?? '',
        lastName: lastName.value ?? '',
        fullName: fullName.value ?? '',
        address: address.value,
        city: city.value,
        state: state.value,
        zipCode: zipCode.value
      }

      acc.push(item)
      return acc
    }, []) as Address[]

    handleComplete(fileName, records)
  }

  return (
    <Space config={spaceConfig}>
      <Workbook config={workbook} onRecordHooks={[[handleRecord]]} onSubmit={handleSubmit} />
    </Space>
  )
}

export default FlatfilePortal
