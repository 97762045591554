import { ReactComponent as ChecklistIcon } from '@/assets/icons/ic-checklist.svg'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'
import FlatfilePortal from '@/flatfile/FlatfilePortal'
import { LookupRequestType } from '@/types/assets'
import { FlatfileProvider } from '@flatfile/react'
import CloseIcon from '@mui/icons-material/Close'
import { Card, IconButton, Modal, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import './upload.scss'

const HowToImportData = ({ type }: { type: LookupRequestType }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const imgSrc =
    type === 'company'
      ? '/assets/images/img-company-sheet.svg'
      : '/assets/images/img-example-sheet.svg'

  return (
    <>
      <Button
        startIcon={<ChecklistIcon fill="currentColor" className="w-4 h-4" />}
        variant="outlined"
        size="large"
        onClick={handleOpen}
      >
        How to import your data?
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'min(464px, 100%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ mt: 0 }} variant="h2">
            How to import your data
          </Typography>

          <ol className="list-decimal pl-5 my-6">
            <li>Upload your file</li>
            <li>Confirm header row selection</li>
            <li>Link your spreadsheet fields to the inputs Archer expects (mapping)</li>
            <li>Finalize and submit to Archer</li>
          </ol>

          <Typography sx={{ mb: 1.5 }} variant="body1" fontWeight={700}>
            How to set up your file
          </Typography>
          <img src={imgSrc} alt="Sample" />

          <Stack direction="row" alignItems="flex-start" gap={1} mt={1.5}>
            <IcCircleInfo className="h-3 w-3 flex-shrink-0 mt-1.5" />
            <div>
              <Typography variant="caption" lineHeight={'16px'} fontWeight={500} fontSize={'12px'}>
                Above columns are mandatory for your file
                <br />
                Allowed file formats: <code>.xlsx, .xls, .csv</code> and other similar file types
              </Typography>
            </div>
          </Stack>

          <div className="flex items-baseline gap-2">
            <Typography mt={1.5} mb={1} variant="caption" fontWeight={700}>
              Use our template to format your data.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              href={
                type === 'address' ? '/assets/example-address.xlsx' : '/assets/example-company.xlsx'
              }
              LinkComponent="a"
              download={
                type === 'address'
                  ? 'Archer Template - Full Address Enrichment'
                  : 'Archer Template - Full Company Enrichment'
              }
            >
              Download template
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

const UploadFileStep = () => {
  const publishableKey = process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY
  if (!publishableKey) {
    throw new Error('Missing FLAT_FILE_PUBLISHABLE_KEY')
  }

  return (
    <Box className="flex flex-col" mt={10}>
      <div className="flex justify-between items-center">
        <Typography variant="h1" color="primary.main" mb={3}>
          Upload your file
        </Typography>
        <HowToImportData type="address" />
      </div>

      <div id="flatfile-view" className="flex-shrink-0 min-w-[901px] h-[1000px] w-full">
        <FlatfileProvider publishableKey={publishableKey}>
          <FlatfilePortal />
        </FlatfileProvider>
      </div>
    </Box>
  )
}

export default UploadFileStep
