import { Flatfile } from '@flatfile/api'

export const workbook: Flatfile.CreateWorkbookConfig = {
  name: 'Bulk Address Lookup',
  labels: ['pinned'],
  sheets: [
    {
      name: 'Address Lookup',
      slug: 'address-lookup',
      access: ['import', 'edit', 'delete'],
      fields: [
        {
          key: 'firstName',
          label: 'First Name',
          type: 'string'
        },
        {
          key: 'lastName',
          label: 'Last Name',
          type: 'string'
        },
        {
          key: 'fullName',
          label: 'Full Name',
          type: 'string'
        },
        {
          key: 'address',
          label: 'Address',
          type: 'string',
          constraints: [
            {
              type: 'required'
            }
          ]
        },
        {
          key: 'city',
          label: 'City',
          type: 'string',
          constraints: [
            {
              type: 'required'
            }
          ]
        },
        {
          key: 'state',
          label: 'State',
          type: 'string',
          constraints: [
            {
              type: 'required'
            }
          ]
        },
        {
          key: 'zipCode',
          label: 'Zip Code',
          type: 'number',
          constraints: [
            {
              type: 'required'
            }
          ]
        }
      ]
    }
  ]
}
